import React, { useState, useContext } from 'react'
import { Link } from 'gatsby'
import tw from 'twin.macro'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { getLastTextIndexes } from '../utils'
import { use100vh } from 'react-div-100vh'

import { Layout, ImageOrVideo } from '../components'
import { media } from '../styles/utils'
import { container, padding, bgImage, bgIcon, maxWidth, button, textDot } from '../styles/global'
import { headerHeight } from '../components/Header/Header'

import { PageTitleContext } from '../context/PageTitleContext'
import { useMount } from 'react-use'
import forEach from 'lodash.foreach'

const PeopleSingle = props => {
    const data = props?.pageContext?.data
    const { pageTitle, setPageTitle } = useContext(PageTitleContext)
    // console.log(data)

    useMount(() => {
        setPageTitle(data?.title)
    })

    const renderMedia = () => {
        if (!data?.news_media) return

        const items = data?.news_media?.map((item, i) => {
            return (
                <ImageOrVideo
                    media_type={item?.media_type}
                    image={item?.image}
                    video={item?.video}
                    video_embed={item?.video_embed}
                />
            )
        })

        return (
            <Items>
                {items}
            </Items>
        )
    }

    return (
        <Layout meta={data && data.seo}>
            <Wrapper>
                <Container>
                    <Grid>
                        <Left>
                            {renderMedia()}
                        </Left>
                        <Right>
                            <Description
                                className={'tiny-mce'}
                                dangerouslySetInnerHTML={{
                                    __html: data?.news_content,
                                }}
                            />
                        </Right>
                    </Grid>
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``
const Item = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`


// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
    padding-top: ${headerHeight};
`

const Container = styled.div`
    ${container};
    ${padding};
`

// Grid System

const Grid = styled.div`
    ${tw`flex border-t border-darkGrey`};
    margin-top: 3rem;
    padding-top: 3rem;
    margin-bottom: 3rem;

    ${media.phone`
        ${tw`flex-col`};
    `}

    > *:first-child,
    > *:last-child {
        ${tw`w-1/2`};

        ${media.phone`
            ${tw`w-full`};
        `}
    }

    > *:first-child {
        padding-right: 1.5rem;

        ${media.phone`
            padding-right: 0;
            margin-bottom: 3rem;
        `}
    }

    > *:last-child {
        padding-left: 1.5rem;

        ${media.phone`
            padding-left: 0;
        `}
    }
`

// Left (media)

const Items = styled.div``
const Left = styled.div`
    ${Items} > *:not(:last-child) {
        margin-bottom: 3rem;
    }
`


// Right (content)

const Right = styled.div`
    ${Description} {
        /* margin-top: 2.5rem; */
    }
`


export default PeopleSingle
